<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => closeShopNickDialog('ShopNickEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="ShopNick"
                    ref="ShopNickEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>昵称框图片</span>
                                </template>
                                <common-upload-component
                                    ref="upload"
                                    :limit=1
                                    :ffileList="ShopNick.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>昵称框信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>状态</span>
                                        </template>
                                        <el-select
                                            v-model="ShopNick.state"
                                            filterable
                                            clearable
                                            placeholder="选择状态"
                                            class="state_c"
                                        >
                                            <el-option
                                                v-for="item in AllState"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>名称</span>
                                        </template>
                                        <el-input v-model="ShopNick.name" placeholder="请填写昵称框名称"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>代码</span>
                                        </template>
                                        <el-input v-model="ShopNick.code" placeholder="请填写昵称框代码"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>类型</span>
                                        </template>
                                        <el-select
                                            v-model="ShopNick.type"
                                            filterable
                                            clearable
                                            placeholder="选择昵称框属性"
                                            class="type_c"
                                        >
                                            <el-option
                                                v-for="item in AllType"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>昵称框说明</span>
                                </template>
                                <common-editor-component
                                    ref="editor"
                                    :fcontent="ShopNick.note"
                                ></common-editor-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>昵称框图片</span>
                                </template>
                                <div>
                                    <img v-if="ShopNick.picture.length" :src="ShopNick.picture[0].url" height="200px" width="150px" alt="" >
                                </div>
                                <common-upload-component
                                    ref="upload"
                                    v-show="false"
                                    :disabled="true"
                                    :ffileList="ShopNick.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>昵称框信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>状态</span>
                                        </template>
                                        <el-tag type="success" v-if="this.is_have_nickframe">已拥有</el-tag>
                                        <el-tag type="danger" v-else>未拥有</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>名称</span>
                                        </template>
                                        <span>{{ShopNick.name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>类型</span>
                                        </template>
                                        <el-tag effect="plain" type="info" v-if="ShopNick.type === 1">普通</el-tag>
                                        <el-tag effect="plain" v-else-if="ShopNick.type === 2">限定</el-tag>
                                        <el-tag effect="plain" type="danger" v-else-if="ShopNick.type === 3">专属</el-tag>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>昵称框说明</span>
                                </template>
                                <div class="el-tiptap-editor__content" v-html="ShopNick.note"></div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="!this.is_have_nickframe && this.UserItems['常规昵称框自选'] && (this.UserItems['常规昵称框自选'] > 0) && (this.ShopNick.type === 1)"
                        type="primary"
                        plain
                        round
                        @click="exchange"
                        >【常规昵称框自选】卡兑换（剩余{{ this.UserItems['常规昵称框自选'] }}张）</el-button
                    >
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="ShopNickDel('ShopNickEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopNickDialog('ShopNickEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ShopNickEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ShopNickEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopNickDialog('ShopNickEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ShopNickEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ShopNickAddRequest,ShopNickDelRequest,ShopNickEditRequest } from '@/network/shop/ShopNick.js'
import { ExchangeNickRequest } from '@/network/user.js'
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'

export default {
    name: 'ShopNickEditComponent',
    data() {
        return {
            login_loading: false,
            ShopNick: {
                state: '',
                name: '',
                picture: [],
                code: '',
                type: '',
                note: ''
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '昵称框详情'
            } else if (this.show_type === 1) {
                return '添加昵称框'
            } else {
                return '修改昵称框'
            }
        },
    },
    methods: {
        closeShopNickDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitShopNickDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopNickInsert(formName)
            })
        },
        ShopNickInsert(formName) {
            this.login_loading = true
            this.ShopNick.picture = this.$refs.upload.fileList
            this.ShopNick.note = this.$refs.editor.content
            ShopNickAddRequest(this.ShopNick)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitShopNickDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            this.ShopNick = {
                state: '',
                name: '',
                picture: [],
                code: '',
                note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopNickEdit(formName)
            })
        },
        ShopNickEdit(formName) {
            this.login_loading = true
            this.ShopNick.id = this.id
            this.ShopNick.picture = this.$refs.upload.fileList
            this.ShopNick.note = this.$refs.editor.content
            ShopNickEditRequest(this.ShopNick)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitShopNickDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.ShopNick.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        ShopNickDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ShopNickDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeShopNickDialog(formName))
                })
                .catch((err) => {})
        },
        exchange() {
            this.$confirm(
                '是否确定兑换？（将扣除一张对应兑换卡，且不支持7天无理由退货！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    ExchangeNickRequest({nick_id: this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('兑换成功！')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.loading = false
                            this.$message.error(err)
                        })
                        .finally(() => {
                            this.$emit('RefreshItems')
                            this.$emit('details_row',this.ShopNick.id)
                        })
                        
                        this.login_loading = false
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        shop_nick_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllState: {
            type: Array,
            default() {
                return []
            }
        },
        AllType: {
            type: Array,
            default() {
                return []
            }
        },
        UserItems: {
            type: Object|Array,
            default() {
                return {}
            }
        },
        is_have_nickframe: {
            type: Boolean,
            default() {
                return true
            }
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonEditorComponent
    },
    watch: {
        shop_nick_details_data(newVal) {
            this.ShopNick = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.state_c,.type_c {
    width: 100%
}
</style>