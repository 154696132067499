import request from '../request'

//昵称框列表
export const ShopNickIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopNick/index',
        params: p
    })
}

//昵称框序列
export const ShopNickListRequest = () => {
    return request({
        method:'GET',
        url:'ShopNick/list'
    })
}

//添加昵称框
export const ShopNickAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopNick/add',
        data: d
    })
}

//删除昵称框
export const ShopNickDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopNick/del',
        data: {
            id: id
        }
    })
}

//修改昵称框
export const ShopNickEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopNick/edit',
        data
    })
}

//获取昵称框详情
export const ShopNickDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopNick/details',
        params: {
            id
        }
    })
}